// @file
// Styles for checkbox form item.

@use '../../../00-config' as *;
@use 'usa-checkbox' as *;

.form-item--checkbox {
  margin: rem(gesso-spacing(1)) 0;

  .form-item__label {
    @extend .usa-checkbox__label;
  }

  &.form-item--id-workflow-508-compliant {
    width: 100%;

    .form-item__description {
      max-width: 31rem;
    }
  }
}
