// @file
// Styles for Pager.

@use '../../00-config' as *;

$pager-background-color: gesso-color(button, primary, background) !default;
$pager-background-color-hover: gesso-color(
  button,
  primary,
  background
) !default;
$pager-background-color-active: gesso-color(
  button,
  primary,
  background-hover
) !default;
$pager-link-color: gesso-color(text, on-light) !default;
$pager-link-color-active: gesso-color(button, primary, text) !default;
$pager-ellipsis-bp: 800px !default;
$pager-bp: 600px !default;

.pager {
  border-top: 1px solid gesso-grayscale(gray-2);
  margin-top: rem(gesso-spacing(3));
  padding-top: rem(gesso-spacing(3));
}

.pager__items {
  @include list-clean();
  clear: both;
  text-align: center;
}

.pager__item {
  display: inline-flex;
  line-height: 1;
  margin: 0;
  padding: 0;
  vertical-align: top;

  &:not([class*='pager__item--']) {
    display: none;

    @include breakpoint($pager-bp) {
      display: inline-flex;
    }
  }
}

%pager__link {
  align-items: center;
  background-color: transparent;
  color: $pager-link-color;
  display: inline-flex;
  justify-content: center;
  min-height: rem(gesso-spacing(5));
  min-width: rem(gesso-spacing(5));
  padding: 0;
}

.pager__item--ellipsis {
  @extend %pager__link;
  display: none;
  padding-right: 0;

  @include breakpoint($pager-ellipsis-bp) {
    display: inline-flex;
  }
}

.pager__item--current.pager__item {
  @extend %pager__link;
  background-color: $pager-background-color;
  color: $pager-link-color-active;
  height: rem(gesso-spacing(5));
}

.pager__link {
  @extend %pager__link;
  text-decoration: none;

  &:visited {
    color: $pager-link-color;
  }

  &:hover,
  &:focus {
    background-color: $pager-background-color-hover;
    color: $pager-link-color-active;
  }

  &:active {
    background-color: $pager-background-color-active;
    color: $pager-link-color-active;
  }
}
