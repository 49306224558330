// @file
// Styles for the Page Layout.

@use '../../00-config' as *;

.l-page {
  margin-bottom: rem(gesso-spacing(4));
  padding-top: rem(gesso-spacing(2));

  &.has-footer {
    margin-bottom: 0;
  }
}

.l-page__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: rem(gesso-spacing(2));

  @include breakpoint(gesso-breakpoint(tablet)) {
    flex-wrap: nowrap;
  }
}

.l-page__header-first {
  margin-bottom: rem(gesso-spacing(2));
  margin-right: rem(gesso-get-map(gutter-width));
}

.l-page__header-last {
  flex-shrink: 0;
}

.l-page__footer {
  background-color: gesso-grayscale('gray-1');
  margin-top: rem(gesso-spacing(4));
  padding-bottom: rem(gesso-site-margins(mobile));
  padding-top: rem(gesso-site-margins(mobile));

  :last-child {
    margin-bottom: 0;
  }

  @include at-media($theme-site-margins-breakpoint) {
    padding-bottom: rem(gesso-site-margins(desktop));
    padding-top: rem(gesso-site-margins(desktop));

    .l-constrain,
    .block {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.l-page__footer-last-updated {
  font-size: font-size(body, 3xs);
  line-height: gesso-line-height(2);
  margin-top: 1rem;
  text-transform: uppercase;

  @include at-media($theme-site-margins-breakpoint) {
    margin-left: 1rem;
    margin-top: 0;
  }
}
