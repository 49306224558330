// @file
// Styles for fields.

@use '../../00-config' as *;
@use '../../02-base' as *;

.field {
  margin-bottom: rem(gesso-spacing(2));
}

.field__label {
  @extend %h4;
  display: block;
  margin-bottom: 0;

  &.is-inline {
    @extend %h5;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 0.25rem;

    &::after {
      content: ':';
    }

    + .field__content {
      display: inline;
    }
  }
}

.field--alternate-font {
  .field__label {
    font-family: gesso-font-family(body);
    font-size: inherit;
    margin-right: rem(units(1));
  }
  .field__content {
    font-weight: gesso-font-weight(bold);
  }
}

.field--inline {
  display: inline-block;
  margin-right: rem(4px);
}
