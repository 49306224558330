// @file
// Styles for radio form item.

@use '../../../00-config' as *;
@use 'usa-radio' as *;

.form-item--radio {
  margin: rem(gesso-spacing(1)) 0;

  .form-item__label {
    @extend .usa-radio__label;
  }
}
