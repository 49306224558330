// @file
// Styles for an Unstyled Button Group.

@use '../../../00-config' as *;
@use 'usa-button' as *;

.button-group--unstyled {
  .button-group__item {
    border: 0;
  }

  .button-group__link {
    @extend .usa-button--unstyled;
    padding: units(1);

    &:focus {
      @extend .usa-button--unstyled, :hover;
    }

    &.is-active {
      @extend .usa-button--unstyled, :active;
      font-weight: gesso-font-weight(bold);
    }
  }
}
