// @file
// Styles for a striped table.

@use '../../../00-config' as *;

.usa-table--striped {
  tbody {
    th {
      background-color: transparent;
    }
  }

  .usa-dark-background & {
    /* stylelint-disable selector-max-compound-selectors */
    tbody {
      th {
        background-color: gesso-grayscale(white);
      }
    }
    /* stylelint-enable selector-max-compound-selectors */
  }
}
