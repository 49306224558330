// @file
// Styles for the Sidebar Layout.

@use '../../00-config' as *;

.l-sidebar {
  @include breakpoint(gesso-breakpoint(sidebar)) {
    display: flex;
  }
}

.l-sidebar__main {
  flex-grow: 1;

  @include breakpoint(gesso-breakpoint(sidebar)) {
    max-width: calc(75% - #{rem(gesso-get-map(gutter-width))});
  }
}

.l-sidebar__sidebar {
  flex-shrink: 0;
  margin-top: rem(gesso-get-map(gutter-width));

  @include breakpoint(gesso-breakpoint(sidebar)) {
    margin-left: rem(gesso-get-map(gutter-width));
    margin-top: 0;
    width: 25%;
  }
}
