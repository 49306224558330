// @file
// Styles for the Reversed Sidebar Layout.

@use '../../../00-config' as *;

.l-sidebar--reversed {
  @include breakpoint(gesso-breakpoint(sidebar)) {
    flex-direction: row-reverse;

    > .l-sidebar__sidebar {
      margin-left: 0;
      margin-right: rem(gesso-get-map(gutter-width));
    }
  }
}
