// @file
// Styles for a teaser.

@use '../../00-config' as *;
@use '../../02-base' as *;

.teaser {
  margin-bottom: rem(gesso-spacing(4));

  > :last-child {
    margin-bottom: 0;
  }

  & + & {
    border-top: 1px solid gesso-grayscale(gray-2);
    padding-top: rem(gesso-spacing(4));
  }
}

.teaser__title {
  @extend %h3;
  margin: 0 0 rem(gesso-spacing('05'));
}

.teaser__subtitle {
  @extend %h4;
  margin: rem(gesso-spacing(1)) 0 rem(gesso-spacing('05'));
}

.teaser__metadata {
  margin: 0 0 rem(gesso-spacing('05'));

  > * {
    margin: 0;
  }
}

.teaser__summary {
  margin-bottom: rem(gesso-spacing('05'));

  > :last-child {
    margin-bottom: 0;
  }
}
