// @file
// Styles for a Sitewide Alert.

@use '../../00-config/00-functions' as *;

.usa-site-alert {
  z-index: 1;
}

.usa-alert__close {
  background: transparent;
  border: 0;
  color: gesso-color(text, link);
  cursor: pointer;
  flex-shrink: 0;
  margin-left: units(3);
  padding: 0;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: gesso-color(text, link-hover);
  }

  &:active {
    color: gesso-color(text, link-active);
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  html.no-js & {
    display: none;
  }
}
