// @file
// Styles for the mobile menu.

@use 'sass:math';
@use '../../00-config' as *;

$mobile-menu-fallback-bg-color: gesso-color(
  ui,
  generic,
  background-light
) !default;
$mobile-menu-bg-color: rgba(
  gesso-color(ui, generic, background-light),
  0.98
) !default;
$mobile-menu-link-color: gesso-color(ui, generic, text-dark) !default;
$mobile-menu-link-hover-color: gesso-color(ui, generic, text-darker) !default;
$mobile-menu-submenu-fallback-bg-color: gesso-color(
  ui,
  generic,
  background-lighter
) !default;
$mobile-menu-submenu-bg-color: rgba(
  gesso-color(ui, generic, background-lighter),
  0.98
) !default;
$mobile-menu-submenu-link-color: gesso-color(ui, generic, text-dark) !default;
$mobile-menu-submenu-link-hover-color: gesso-color(
  ui,
  generic,
  text-darker
) !default;
$mobile-menu-button-height: rem(54px);
$mobile-menu-submenu-link-hover-color: gesso-color(
  ui,
  generic,
  text-darker
) !default;
$mobile-menu-button-height: rem(54px);
$mobile-menu-button-width: rem(44px);
$mobile-menu-font-size: font-size(ui, md);
$mobile-menu-line-height: line-height(ui, $theme-body-line-height);

.mobile-menu {
  background-color: $mobile-menu-fallback-bg-color;
  background-color: $mobile-menu-bg-color;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
}

.mobile-menu__menu {
  @include list-clean();
  margin: 0.75em 0 0;
}

.mobile-menu__item {
  margin: 0;
  padding: 0;
  position: relative;
}

.mobile-menu__link {
  background-color: transparent;
  border: 0;
  color: $mobile-menu-link-color;
  display: block;
  font-family: gesso-font-family(ui);
  font-size: rem($mobile-menu-font-size);
  font-weight: gesso-font-weight(bold);
  line-height: $mobile-menu-line-height;
  margin: 0;
  padding: (
      $mobile-menu-button-height -
        math.div($mobile-menu-font-size * $mobile-menu-line-height, 2)
    )
    gesso-spacing(2);
  text-align: left;
  text-decoration: none;
  white-space: normal;
  width: auto;
  z-index: 3;

  &:hover,
  &:focus,
  &:active,
  &.is-active {
    color: $mobile-menu-link-hover-color;
  }

  &.has-subnav {
    margin-right: $mobile-menu-button-width;
  }
}

.mobile-menu__subnav-arrow {
  @include image-replace($mobile-menu-button-width, $mobile-menu-button-height);
  @include svg-background-inline(mobile-arrow-down);
  background-attachment: initial;
  background-color: initial;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 19px 12px;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  outline-offset: 0;
  position: absolute;
  right: 0;
  top: 0;
  vertical-align: top;
  z-index: 2;

  &:focus {
    @include focus-outline();
  }

  @if $support-for-rtl {
    [dir='rtl'] & {
      left: 0;
      right: auto;
    }
  }

  &[aria-expanded='true'] {
    @include svg-background-inline(mobile-arrow-up);
  }
}

.mobile-menu__subnav {
  background-color: $mobile-menu-submenu-fallback-bg-color;
  background-color: $mobile-menu-submenu-bg-color;
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;

  .mobile-menu__link {
    color: $mobile-menu-submenu-link-color;
    padding-left: gesso-spacing(3);

    &:hover,
    &:focus,
    &:active {
      color: $mobile-menu-submenu-link-hover-color;
    }
  }
}
