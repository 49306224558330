// @file
// Styles for Hublinks.

@use '../../00-config/00-functions' as *;

.hublinks {
  line-height: gesso-line-height(2);
}

.hublinks__title {
  margin-right: rem(units(1));
}

.hublinks__list {
  display: inline;
  line-height: gesso-line-height(2);
  list-style: none;
  margin: 0;
  padding: 0;
}

.hublinks__list-item {
  border-right: 1px solid gesso-grayscale('gray-5');
  display: inline;
  margin-right: rem(units(1));
  padding-right: rem(units(1));

  &:last-child {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
  }

  > a {
    font-weight: gesso-font-weight(bold);
  }
}
