// @file
// Styles for a Special Box.

@use '../../../00-config' as *;
@use '../../../02-base/02-html-elements/13-headings/headings' as *;
@use '../../../02-base/03-extendables' as *;

.box--special {
  background: gesso-grayscale(gray-1);
  padding: 0 rem(units('205'));

  &.has-image-header {
    max-width: rem(320px) + rem(units(5));
  }

  > .box__title,
  > .box__content,
  > .box__footer {
    margin: 0;
    padding: rem(units('205')) 0;
  }

  > .box__title {
    @extend %h3;
    color: gesso-color(text, primary);
  }

  > .box__image,
  > .box__image-link {
    margin: rem(units('205')) 0;
  }

  > * + .box__image,
  > * + .box__image-link,
  > * + .box__content,
  > * + .box__footer {
    margin-top: rem(units('05')) * -1;
    padding-top: 0;
  }

  > .box__content {
    > :last-child {
      margin-bottom: 0;
    }
  }

  .usa-dark-background & {
    color: gesso-color(text, primary);

    a:not(.usa-button, .button) {
      @extend %light-bg-link;
    }

    p,
    span {
      color: gesso-color(text, primary);
    }
  }

  .usa-section--light & {
    background: gesso-grayscale(white);
  }
}
