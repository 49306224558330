// @file
// Styles for the sidenav menu.

@use '../../../00-config' as *;
@use 'usa-sidenav' as *;

.menu--sidenav {
  @extend .usa-sidenav;

  .menu__item {
    @extend .usa-sidenav__item;
  }

  .menu__link {
    color: gesso-color(text, link) !important;

    &:hover,
    &:focus {
      color: gesso-color(text, link-hover) !important;
    }

    &:active {
      color: gesso-color(text, link-active) !important;
    }

    &:visited {
      color: gesso-color(text, link-visited) !important;
    }

    &.is-active,
    &.is-active-trail {
      @extend .usa-current;
    }
  }

  .menu__subnav {
    @extend .usa-sidenav__sublist;
  }
}
