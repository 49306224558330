// @file
// Styles for a Facet Limit.

@use '../../00-config' as *;
@use 'usa-button' as *;

.facet-limit,
.facets-soft-limit-link {
  @extend .usa-button--unstyled;
  cursor: pointer;
  display: block;
  font-size: font-size(body, 2xs);
  margin-top: rem(gesso-spacing(4));
  text-decoration: none;
  width: 100%;
}
